import axios from 'axios'
import store from '@/store/index'
import { Message } from 'element-ui'

// 创建axios实例
const instance = axios.create({
  timeout: 20000 // 请求超时时间 20s
})
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// request拦截器
instance.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers.Authorization = 'Bearer ' + store.getters.token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// response拦截器
instance.interceptors.response.use(
  response => {
    return response.data // 响应数据
  },
  error => { // 默认除了2XX之外的都是错误的，就会走这里
    if (error.response) {
      console.log(error)
      const status = error.response.status
      let message = 'http response error'
      if (error.response.data) {
        message = error.response.data.msg || 'http response error'
      }
      switch (status) {
        case 401:
          store.dispatch('FedLogOut').then(() => {
            window.location.href = window.location.origin + '#/login'
            location.reload() // 重新实例化vue-router对象 避免bug
          })
          break
        case 403:
          // router.push({path: '/403'})
          store.dispatch('FedLogOut').then(() => {})
          Message.error(`${status}: 没有操作权限`)
          break
        case 302:
          break
        default:
          Message.error(`${status}: ${message}`)
      }
    }
    return Promise.reject(error.response)
  }
)

export default instance
