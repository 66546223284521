/**
 * 请求接口方法
 * @author Mr.姜
 * @param http  封装的axios请求方式
 * @since 2018/08/13
 * */

import http from '@/api/http'

export default {
  /**
   * @returns 产品
   */
  // 获取产品列表
  getAppData () {
    return http.get('/channel/app')
  },
  // 新增产品
  addProduct (params) {
    return http.post('/channel/app', params)
  },
  // 删除产品
  deleteProduct (id) {
    return http.delete(`/channel/app/${id}`)
  },
  // 修改产品
  changeProduct (data) {
    return http.put(`/channel/app/${data.id}`, data)
  },
  // 货币查询
  getCurrencyData (params) {
    return http.get('/channel/admin/purchases', { params: params })
  },
  // 货币增改
  addOrChangeCurrency (params) {
    return http.put('/channel/admin/purchases', params)
  },
  /**
   * @returns 渠道
   */
  // 获取渠道列表
  getChannelData (appId) {
    return http.get(`/channel/app/${appId}/channel`)
  },
  // 获取渠道列表
  getChannelDataByPage (params) {
    return http.get('/channel/channel/pages', { params: params })
  },
  // 添加渠道
  addChannel (params) {
    return http.post('/channel/channel', params)
  },
  // 删除渠道
  deleteChannel (id) {
    return http.delete(`/channel/channel/${id}`)
  },
  // 修改渠道
  changeChannel (data) {
    return http.put(`/channel/channel/${data.id}`, data)
  },
  /**
   * @returns 平台
   */
  // 获取平台列表
  getPlatformData (appId) {
    return http.get(`/channel/app/${appId}/platform`)
  },
  // 获取平台列表
  getPlatformDataByPage (params) {
    return http.get('/channel/platform/pages', { params: params })
  },
  // 新增平台
  addPlatform (params) {
    return http.post('/channel/platform', params)
  },
  // 删除平台
  deletePlatform (id) {
    return http.delete(`/channel/platform/${id}`)
  },
  // 修改平台
  changePlatform (data) {
    return http.put(`/channel/platform/${data.id}`, data)
  },
  /**
   * @returns 渠道类型
   */
  // 获取渠道类型列表
  getChannelTypeDataByPage (params) {
    return http.get('/channel/channelType/pages', { params: params })
  },
  getChannelTypeList () {
    return http.get('/channel/channelType')
  },
  getChannelTypeListByPackageType (packageType) {
    return http.get('/channel/channelType', { params: { packageType: packageType } })
  },
  // 新增渠道类型
  addChannelType (params) {
    return http.post('/channel/channelType', params)
  },
  // 更新渠道类型
  updateChannelType (params) {
    return http.put(`/channel/channelType/${params.id}`, params)
  },
  // 删除渠道类型
  deleteChannelType (id) {
    return http.delete(`/channel/channelType/${id}`)
  },
  /**
   * @returns 逻辑
   */
  // 获取逻辑列表
  getLogicData () {
    return http.get('/channel/logic/')
  },
  // 分页获取逻辑列表
  getLogicDataByPage (params) {
    return http.get('/channel/logic/pages', { params: params })
  },
  // 新增逻辑
  addLogic (params) {
    return http.post('/channel/logic', params)
  },
  // 删除平台
  deleteLogic (id) {
    return http.delete(`/channel/logic/${id}`)
  },
  // 修改平台
  changeLogic (data) {
    return http.put(`/channel/logic/${data.id}`, data)
  },
  /**
   * @returns 参数
   */
  // 获取参数
  getArgs (id) {
    return http.get(`/channel/args/${id}`)
  },
  getArgsData () {
    return http.get('/channel/args/')
  },
  // 新增参数
  addArgs (params) {
    return http.post('/channel/args', params)
  },
  // 删除参数
  deleteArgs (id) {
    return http.delete(`/channel/args/${id}`)
  },
  // 修改参数
  changeArgs (data) {
    return http.put(`/channel/args/${data.id}`, data)
  },
  /**
   * @returns 节点
   */
  getNodeList (params) {
    return http.get('/channel/node', { params: params })
  },
  // 通过type操作节点
  handleArgsByType (cid, type, params) {
    return http[type](`/channel/node/sync/${cid}`, { params: params }, { params: params })
  },
  // 获取渠道参数信息
  getChannelArgs (cid, params) {
    return http.get(`/channel/node/sync/${cid}`, { params: params })
  },
  // 获取app参数信息
  getAppArgs (appId, params) {
    return http.get(`/channel/node/game/${appId}`, { params: params })
  },
  // 修改app参数信息
  changeAppArgs (appId, params) {
    return http.put(`/channel/node/game/${appId}`, { params: params }, { params: params })
  },
  handleNodeHoliday (type = 'get', year, params) {
    const url = `/channel/node/holiday/${year}`
    switch (type) {
      case 'get':
        return http.get(url, { params: params })
      case 'put':
        return http.put(url, {}, { params: params })
      case 'delete':
        return http.delete(url, { params: params })
    }
  },
  /**
   * @returns 广告
   */
  // 获取广告应用
  getAdnetGames (id) {
    const url = id ? '/' + id : ''
    return http.get('/adnet/admin/games' + url)
  },
  // 新增广告应用
  postAdnetGames (params) {
    return http.post('/adnet/admin/games', params)
  },
  // 修改广告应用
  putAdnetGames (params) {
    return http.put(`/adnet/admin/games/${params.id}`, params)
  },
  // 删除广告应用
  deleteAdnetGames (id) {
    return http.delete(`/adnet/admin/games/${id}`)
  },
  /**
   * @returns 广告渠道管理
   */
  // 获取广告渠道
  getAdnetChannels (params) {
    return http.get('/adnet/admin/channels', { params: params })
  },
  // 新增广告渠道
  postAdnetChannels (params) {
    return http.post('/adnet/admin/channels', params)
  },
  // 修改广告渠道
  putAdnetChannels (params) {
    return http.put(`/adnet/admin/channels/${params.channelId}`, params)
  },
  // 删除广告渠道
  deleteAdnetChannels (id) {
    return http.delete(`/adnet/admin/channels/${id}`)
  },
  /**
   * 组织管理页面接口
   * @param type Http请求的类型
   * @param id ID
   * @param params 参数集合
   * @returns {Promise<AxiosResponse<any>>|IDBRequest<IDBValidKey>|Promise<void>|*}
   */
  httpOrganizeData ({ type = 'get', id, params } = {}) {
    switch (type) {
      case 'get':
        return http.get('/channel/admin/organize/')
      case 'post':
        return http.post('/channel/admin/organize/', params)
      case 'put':
        return http.put(`/channel/admin/organize/${id}`, params)
      case 'delete':
        return http.delete(`/channel/admin/organize/${id}`)
    }
  },
  // 节假日
  getHolidays () {
    return http.get('/channel/admin/holiday')
  },
  addHoliday (data) {
    return http.post('/channel/admin/holiday', data)
  },
  getHoliday (id) {
    return http.get(`/channel/admin/holiday/${id}`)
  },
  updateHoliday (data) {
    return http.put(`/channel/admin/holiday/${data.id}`, data)
  },
  deleteHoliday (id) {
    return http.delete(`/channel/admin/holiday/${id}`)
  },
  /**
   * @returns 环境管理
   */
  // 获取环境
  getAreas () {
    return http.get('/channel/admin/area')
  },
  // 新增环境
  addArea (data) {
    return http.post('/channel/admin/area', data)
  },
  getArea (id) {
    return http.get(`/channel/admin/area/${id}`)
  },
  // 修改环境
  updateArea (data) {
    return http.put(`/channel/admin/area/${data.id}`, data)
  },
  // 删除环境
  deleteArea (id) {
    return http.delete(`/channel/admin/area/${id}`)
  },
  ufwBlacklists (params) {
    return http.get('/channel/node/ufw/blacklists', { params: params })
  },
  ufwWhitelists (params) {
    return http.get('/channel/node/ufw/whitelists', { params: params })
  },
  ufwReload (params) {
    return http.get('/channel/node/ufw/reload', { params: params })
  },
  getPkgList (params) {
    return http.get('/channel/admin/api/pkgConfigs', { params })
  },
  handlePkgRequest (pkgName, type, params) {
    return http[type](`/channel/node/pkgConfig/${pkgName}`, { params }, { params })
  },
  visitorPull (params) {
    return http.get('/channel/node/visitor/pull', { params: params })
  },
  getUfoGroups () {
    return http.get('/channel/admin/ufoGroup')
  },
  addUfoGroup (data) {
    return http.post('/channel/admin/ufoGroup', data)
  },
  getUfoGroup (id) {
    return http.get(`/channel/admin/ufoGroup/${id}`)
  },
  updateUfoGroup (data) {
    return http.put(`/channel/admin/ufoGroup/${data.id}`, data)
  },
  deleteUfoGroup (id) {
    return http.delete(`/channel/admin/ufoGroup/${id}`)
  }
}
