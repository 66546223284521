<template>
  <!-- 左侧边栏-->
  <el-aside style="background-color: #304156; text-align: left; width: auto; height: 100%; z-index: 1001;" v-show="sidebarShow">
    <a class="logo" href="#/">
      <font-awesome-icon icon="fa fa-home"/>
      <span class="logo-title" v-if="!isCollapse">Channel Admin</span>
    </a>
    <el-menu background-color="#304156" text-color="#BFCBD9" active-text-color="#409EFF" :router=true :collapse="isCollapse" :unique-opened="true" :default-active="$route.path" class="left-menu-vertical">
      <template v-for="(item, i) in visibleMenuRoutes">
        <el-submenu v-if="item.subMenu" :index="item.path" :key="i">
          <template slot="title">
            <font-awesome-icon :icon="item.icon"/>
            <span class="menu-title" slot="title">{{item.name}}</span>
          </template>
          <el-menu-item :index="child.path" v-for="(child, i) in item.children" :key="i">
            <font-awesome-icon :icon="child.icon"/>
            <span class="menu-title" slot="title">{{child.name}}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.children[0].path" :key="i">
          <font-awesome-icon :icon="item.children[0].icon"/>
          <span class="menu-title" slot="title">{{item.children[0].name}}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: 'AltSidebar',
  computed: {
    device () {
      return this.$store.getters.device
    },
    sidebar () {
      return this.$store.getters.sidebar
    },
    visibleMenuRoutes () {
      return this.$router.options.routes.filter(item => !item.hidden)
    },
    isMobile () {
      return this.device === 'mobile'
    },
    isCollapse () {
      return !this.sidebar.opened
    },
    sidebarShow () {
      return !this.isMobile || !this.isCollapse
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .el-aside {
    color: #BFCBD9;
    background: #304156;
    border-right: 3px solid #F0F2F5;
  }
  .el-menu {
    border: none;
  }
  .el-menu i {
    color: #BFCBD9;
  }
  .left-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .menu-title {
    margin-left: 10px;
  }
  .logo {
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #304156;
    color: #ffffff;
    border-bottom: 3px solid #4b545c;
    text-decoration: none;
  }
  .logo-title {
    margin-left: 20px;
  }
</style>
