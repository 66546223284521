import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'
import Layout from '@/components/Layout'

Vue.use(Router)

function load (view) {
  // return () => import(`@/views/${view}.vue`)
  return resolve => require([`@/views/${view}.vue`], resolve)
}

export default new Router({
  routes: [
    { path: '/login', component: load('Login'), hidden: true },
    {
      path: '/',
      component: Layout,
      hidden: true,
      redirect: '/home',
      children: [{ path: '/home', name: '首页', component: load('index'), icon: 'home' }]
    },
    {
      path: '/p',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/p', name: '产品管理', component: load('Product/index'), icon: 'fab fa-product-hunt' }]
    },
    {
      path: '/pl',
      component: Layout,
      children: [{ path: '/pl', name: '平台管理', component: load('Platform/index'), icon: 'fa-sliders' }]
    },
    {
      path: '/l',
      component: Layout,
      children: [{ path: '/l', name: '逻辑管理', component: load('Logic/index'), icon: 'fa-microchip' }]
    },
    {
      path: '/c',
      component: Layout,
      children: [{ path: '/c/cl', name: '渠道列表', component: load('Channel/index'), icon: 'fa-c' }]
    },
    {
      path: '/n',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/n', name: '同步参数', component: load('Node/index'), icon: 'fa-rotate' }]
    },
    {
      path: '/ct',
      component: Layout,
      children: [{ path: '/ct', name: 'ChannelType', component: load('ChannelType/index'), icon: 'fa-tenge' }]
    },
    {
      path: '/pkg',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/pkg', name: '证书配置', component: load('Pkg/index'), icon: 'fa-certificate' }]
    },
    {
      path: '/adnet',
      component: Layout,
      name: '广告变现',
      subMenu: true,
      icon: 'fab fa-adversal',
      children: [
        { path: '/ad', name: '广告应用', component: load('Advertising/index'), icon: 'fab fa-adversal' },
        { path: '/adc', name: '广告渠道', component: load('AdvertisingChannel/index'), icon: 'fa-yen-sign' }
      ]
    },
    {
      path: '/config',
      component: Layout,
      name: '环境设置',
      subMenu: true,
      icon: 'fa-gear',
      children: [
        { path: '/ufo/group', name: 'UFO分组', component: load('UfoGroup/index'), icon: 'fa-layer-group' },
        { path: '/area', name: '网关域名', component: load('Area/index'), icon: 'fa-object-group' },
        { path: '/company', name: '组织管理', component: load('Company/index'), icon: 'fa-building-columns' },
        { path: '/holiday', name: '节假日管理', component: load('Holiday/index'), icon: 'fa fa-calendar' }
      ]
    },
    {
      path: '/ufw',
      component: Layout,
      name: 'ufw管理',
      subMenu: true,
      icon: 'fa-shield-heart',
      children: [
        { path: '/ufw', name: '节点内存', component: load('Ufw/index'), icon: 'fa-memory' },
        { path: '/ufw/black', name: 'ufw黑名单', component: load('Ufw/blackUfw'), icon: 'fa-solid fa-passport' },
        { path: '/ufw/white', name: 'ufw白名单', component: load('Ufw/whiteUfw'), icon: 'fa-passport' }
      ]
    },
    // Always leave this last one
    { path: '*', component: load('404'), hidden: true }
  ]
})
